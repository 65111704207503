import {
  CareerSiteFiltersOption,
  CareerSiteSettings,
  CategoryOptions,
  RadiusOptions,
} from 'api-clients/monolith';
import React from 'react';

import { Filters, FiltersSkeleton } from 'components/filters';
import { Skeleton } from 'components/ui/skeleton';

import {
  ExperienceLevelCards,
  JobExperienceLevelCardsSkeleton,
} from './experience-level-cards';
import {
  JobCategoryCards,
  JobCategoryCardsSkeleton,
} from './job-category-cards';
import { JobsSection, JobsSectionSkeleton } from './jobs-section';

export const HOME_JOB_PER_PAGE = 3;

interface HomeMainProps {
  scrollToJobs: () => void;
  brandSetting: CareerSiteSettings;
  radiusOptions: RadiusOptions[];
  categoryOptions: CategoryOptions[];
  departmentOptions: CareerSiteFiltersOption[];
  locationOptions: CareerSiteFiltersOption[];
  positionOptions: CareerSiteFiltersOption[];
  compensationTypeOptions: CareerSiteFiltersOption[];
}

export function HomeMain({
  scrollToJobs,
  brandSetting,
  radiusOptions,
  categoryOptions,
  departmentOptions,
  locationOptions,
  positionOptions,
  compensationTypeOptions,
}: HomeMainProps) {
  return (
    <>
      <h1 className="text-32 font-500 leading-10 text-gray-700 text-center">
        Find your job
      </h1>

      <Filters
        brandSetting={brandSetting}
        radiusOptions={radiusOptions}
        categoryOptions={categoryOptions}
        departmentOptions={departmentOptions}
        locationOptions={locationOptions}
        positionOptions={positionOptions}
        compensationTypeOptions={compensationTypeOptions}
      />

      <JobsSection />

      {brandSetting?.categorize_jobs_by_job_type && (
        <div className="flex flex-col gap-3">
          <h2 className="text-24 font-500 leading-8">Top Categories</h2>
          <JobCategoryCards
            scrollToJobs={scrollToJobs}
            categoryOptions={categoryOptions}
            radiusOptions={radiusOptions}
            departmentOptions={departmentOptions}
            locationOptions={locationOptions}
            positionOptions={positionOptions}
          />
        </div>
      )}

      {brandSetting?.categorize_jobs_by_experience_level && (
        <div className="flex flex-col gap-3">
          <h2 className="text-24 font-500 leading-8">
            View Jobs by Experience Level
          </h2>
          <ExperienceLevelCards scrollToJobs={scrollToJobs} />
        </div>
      )}
    </>
  );
}

HomeMain.Skeleton = function HomeMainSkeleton() {
  return (
    <>
      <Skeleton className="h-10 w-full md:w-1/3 mx-auto" />
      <FiltersSkeleton />
      <JobsSectionSkeleton />
      <div className="flex flex-col gap-3">
        <Skeleton className="h-8 w-full md:w-1/3" />
        <JobCategoryCardsSkeleton />
      </div>
      <div className="flex flex-col gap-3">
        <Skeleton className="h-8 w-full md:w-1/3" />
        <JobExperienceLevelCardsSkeleton />
      </div>
    </>
  );
};
