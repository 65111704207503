import { ActiveFilters, AppContext, Segment } from 'contexts/app-context';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { useJobs } from 'hooks/useJobs';
import { useWindowResize } from 'hooks/useWindowResize';

const defaultFilterData: ActiveFilters = {
  compensationType: { key: 'any', label: 'Any' },
  sortBy: { key: 'distance', label: 'Distance' },
  radius: { key: 'Any', label: 'Any' },
  category: { key: 'Any', label: 'Any' },
  department: { key: 'all', label: 'All' },
  location: { key: 'current_location', label: 'Current Location' },
  position: { key: 'all', label: 'All' },
};
const defaultLocPosState = { id: null, value: null };

interface AppContextProviderProps {
  children: ReactNode;
}

export function AppContextProvider({ children }: AppContextProviderProps) {
  const { currentCity } = useJobs();
  const [accountSlug, setAccountSlug] = useState('');
  const [brandId, setBrandId] = useState('');
  const [brandName, setBrandName] = useState('');
  const [activeFilters, setActiveFilters] =
    useState<ActiveFilters>(defaultFilterData);
  const [location, setLocation] = useState<Segment>(defaultLocPosState);
  const [position, setPosition] = useState<Segment>(defaultLocPosState);
  const [categoryName, setCategoryName] = useState<string | null>(null);
  const [experienceLevelName, setExperienceLevelName] = useState<string | null>(
    null,
  );
  const [placeId, setPlaceId] = useState<string | null>(null);
  const [jobSearchQuery, setJobSearchQuery] = useState<string | null>(null);
  const [isFetchingJobs, setIsFetchingJobs] = useState(false);
  const [page, setPage] = useState(1);
  const [isJobsFromCurrentLocation, setIsJobsFromCurrentLocation] =
    useState(true);
  const [isCmsAccount, setIsCmsAccount] = useState(false);

  const { isMobile } = useWindowResize();

  const setBrandDetails = useCallback(
    (accountSlug: string, brandId: string, brandName: string) => {
      setAccountSlug(accountSlug);
      setBrandId(brandId);
      setBrandName(brandName);
      setIsCmsAccount(accountSlug === 'cms');
    },
    [],
  );

  const setDefaultFilterData = useCallback((data: ActiveFilters) => {
    setActiveFilters(data);
  }, []);

  const resetFilters = useCallback(() => {
    setActiveFilters({
      ...defaultFilterData,
      location: {
        key: 'current_location',
        label: currentCity ?? 'Current City',
      },
    });
    setPosition(defaultLocPosState);
    setCategoryName(null);
    setExperienceLevelName(null);
    setPlaceId(null);
    setJobSearchQuery(null);
    setPage(1);
    setIsJobsFromCurrentLocation(false);
  }, [currentCity]);

  useEffect(() => {
    setActiveFilters({
      ...defaultFilterData,
      location: {
        key: 'current_location',
        label: currentCity ?? 'Current City',
      },
    });
  }, [currentCity]);

  return (
    <AppContext.Provider
      value={{
        accountSlug,
        brandId,
        brandName,
        activeFilters,
        setActiveFilters,
        location,
        setLocation,
        position,
        setPosition,
        categoryName,
        setCategoryName,
        experienceLevelName,
        setExperienceLevelName,
        setBrandDetails,
        placeId,
        setPlaceId,
        jobSearchQuery,
        setJobSearchQuery,
        isFetchingJobs,
        setIsFetchingJobs,
        resetFilters,
        page,
        setPage,
        isMobile,
        isJobsFromCurrentLocation,
        setIsJobsFromCurrentLocation,
        setDefaultFilterData,
        isCmsAccount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
