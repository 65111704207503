import {
  CareerSiteFiltersOption,
  CareerSiteSettings,
  CategoryOptions,
  RadiusOptions,
} from 'api-clients/monolith';
import { AppContext, sortOptions } from 'contexts/app-context';
import React, { useContext, useEffect } from 'react';

import { Dropdown } from 'components/ui/dropdown';

interface FiltersProps {
  brandSetting: CareerSiteSettings;
  radiusOptions: RadiusOptions[];
  categoryOptions: CategoryOptions[];
  departmentOptions: CareerSiteFiltersOption[];
  locationOptions: CareerSiteFiltersOption[];
  positionOptions: CareerSiteFiltersOption[];
  compensationTypeOptions: CareerSiteFiltersOption[];
}

export function FiltersSkeleton() {
  const { isMobile } = useContext(AppContext);
  return (
    <div className="flex flex-col md:flex-row justify-center gap-2">
      <Dropdown.Skeleton />
      <Dropdown.Skeleton />
      <Dropdown.Skeleton />
      {isMobile && <Dropdown.Skeleton />}
    </div>
  );
}

export function Filters({
  brandSetting,
  radiusOptions,
  categoryOptions,
  departmentOptions,
  locationOptions,
  positionOptions,
  compensationTypeOptions,
}: FiltersProps) {
  const {
    activeFilters,
    isFetchingJobs,
    isMobile,
    page,
    setDefaultFilterData,
    setPlaceId,
    isCmsAccount,
    setPage,
  } = useContext(AppContext);

  useEffect(() => {
    departmentOptions.unshift({ key: 'all', label: 'All' });
    locationOptions.unshift({ key: 'all', label: 'All' });
    positionOptions.unshift({ key: 'all', label: 'All' });
  }, [departmentOptions, locationOptions, positionOptions]);

  useEffect(() => {
    setDefaultFilterData({
      ...activeFilters,
      radius: { ...radiusOptions[6] }, // 6 is Any default Radius
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDefaultFilterData]);

  const handleFilterChange = (
    type: string,
    option: { key: string; label: string },
  ) => {
    setPage(1);
    if (isCmsAccount) {
      setPlaceId(null);
    }
    setDefaultFilterData({
      ...activeFilters,
      [type]: option,
    });
  };

  if (
    (!isMobile && isFetchingJobs) ||
    (isMobile && isFetchingJobs && page === 1)
  ) {
    return <FiltersSkeleton />;
  }

  const filterMeta = {
    ...(brandSetting?.categorize_jobs_by_job_type
      ? {
          category: {
            label: 'Category',
            options: categoryOptions,
            showFilter: !isCmsAccount,
          },
        }
      : {}),
    ...(brandSetting?.display_compensation_type
      ? {
          compensationType: {
            label: 'Compensation type',
            options: compensationTypeOptions,
            showFilter: !isCmsAccount,
          },
        }
      : {}),
    radius: {
      label: 'Radius',
      options: radiusOptions,
      showFilter: !isCmsAccount,
    },
    sortBy: {
      label: 'Sort by',
      options: sortOptions,
      showFilter: !isCmsAccount,
    },
    location: {
      label: 'Location',
      options: locationOptions,
      showFilter: isCmsAccount,
    },
    department: {
      label: 'Department',
      options: departmentOptions,
      showFilter: isCmsAccount,
    },
    position: {
      label: 'Position',
      options: positionOptions,
      showFilter: isCmsAccount,
    },
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-2">
      {Object.entries(filterMeta).map(
        ([key, filter]) =>
          filter.showFilter && (
            <Dropdown
              key={filter.label}
              label={filter.label}
              type={key}
              value={activeFilters[key as keyof typeof filterMeta].label}
              options={filter.options}
              onSelect={handleFilterChange}
            />
          ),
      )}
    </div>
  );
}
